<template>
  <div>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        ref="singleDataModal"
        centered
        :title="'Fichier: ' + singleData.slug"
        ok-only
        ok-title="Fermer"
      >
        <b-card-text>
          Nom:<b> {{ singleData.name }}</b>
          <hr />
        </b-card-text>
        <b-card-text>
          Département:<b> {{ singleData.zone }}</b>
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.contacts">
          Contacts:<b> {{ singleData.contacts.join('-') }}</b>
          <hr />
        </b-card-text>

        <b-card-text v-if="singleData.updatedAt">
          Derniere mise à jour:<b>
            {{ this.$coddyger.cdgDateFormat(singleData.updatedAt, true) }}</b
          >
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.createdAt">
          Date de création:<b>
            {{ this.$coddyger.cdgDateFormat(singleData.createdAt) }}</b
          >
          <hr />
        </b-card-text>
      </b-modal>
      <!-- NEW RECORD -->
      <b-modal
        id="saveModal"
        ref="saveModal"
        title="Nouveau"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Nouveau
          <hr />
          <small class="text-info">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="save">
          <!-- FULLNAME -->
          <b-form-group
            label="* Nom complet"
            label-for="name"
          >
            <b-form-input
              v-model="formdata.name"
              id="name"
              placeholder="Nom complet"
              required
            />
          </b-form-group>
          <!-- DEPARTEMENT -->
          <b-form-group
            label="* Département"
            label-for="zone"
          >
            <b-form-input
              v-model="formdata.zone"
              id="zone"
              placeholder="Département"
              required
            />
          </b-form-group>
          <!-- CONTACTS -->
          <b-form-group
            label="Contacts"
            label-for="contacts"
          >
            <b-form-tags
                v-model="formdata.contacts"
                :limit="formdata.tagLimit"
                separator=" ,;"
                remove-on-delete
                addButtonText="Ajouter"
                duplicateTagText="Existe déjà"
                limitTagsText="Maximum atteint"
                id="contact"
                input-id="tags-contact"
                class="mb-2"
                placeholder="Contacts"
              />
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('save')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                @click="save"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- EDIT RECORD -->
      <b-modal
        id="editModal"
        ref="editModal"
        title="Modification"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Modification
          <hr />
          <small class="text-info">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="save">
          <!-- FULLNAME -->
          <b-form-group
            label="* Nom complet"
            label-for="name"
          >
            <b-form-input
              v-model="formdata.name"
              id="name"
              placeholder="Nom complet"
              required
            />
          </b-form-group>
          <!-- DEPARTEMENT -->
          <b-form-group
            label="* Département"
            label-for="zone"
          >
            <b-form-input
              v-model="formdata.zone"
              id="zone"
              placeholder="Département"
              required
            />
          </b-form-group>
          <!-- CONTACTS -->
          <b-form-group
            label="Contacts"
            label-for="contacts"
          >
            <b-form-tags
                v-model="formdata.contacts"
                :limit="formdata.tagLimit"
                separator=" ,;"
                remove-on-delete
                addButtonText="Ajouter"
                duplicateTagText="Existe déjà"
                limitTagsText="Maximum atteint"
                id="contact"
                input-id="tags-contact"
                class="mb-2"
                placeholder="Contacts"
              />
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('edit')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                @click="save"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button class="mr-1" variant="primary" @click="openSaveDialog">
              Nouveau
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="buildData"
        responsive
        :fields="columns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.slug}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-1"
              @click.stop="openDialog(data.item)"
            />

            <feather-icon
                :id="`invoice-row-${data.item.slug}-edit-icon`"
                icon="EditIcon"
                size="16"
                class="mr-1"
                @click.stop="openEditDialog(data.item)"
              />

            <feather-icon
              :id="`invoice-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormTags
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ModuleSecurity from "@/store/modules/security/security.module";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as payloads from "./payloads";
import { $themeConfig } from "@themeConfig";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BFormTags,

    vSelect,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      singleData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 30],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      columns: [
        { key: "name", label: "Nom", sortable: true },
        { key: "zone", label: "Département", sortable: true },
        { key: "actions" },
      ],
      rows: [],
      searchTerm: "",
      locale: "fr",

      // Data NEW/EDIT Modal
      formdata: {
        slug: null,
        //-FORM-PROPERTIES-
        name: "",
        contacts: [],
        zone: "",
        tagLimit: 4
      },
      isModalActive: false,
      modalData: [],
    };
  },
  created() {
    if (!ModuleSecurity.isRegistered) {
      this.$store.registerModule("ModuleSecurity", ModuleSecurity);
      ModuleSecurity.isRegistered = true;
    }
    this.$store.dispatch("ModuleSecurity/fetchUser", this.buildCurrentPath()).then((data)=> {

    }).catch((e) => {
      this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Erreur`,
                  icon: "WarningIcon",
                  variant: "danger",
                  text: `Impossible de charger la liste des données. Merci de réessayer plutard.`,
                },
              });
    });
  },
  computed: {
    buildData() {
      const data = this.$store.state.ModuleSecurity.user;
      this.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isModalActive() {
      this.initValues();
    },
  },

  methods: {
    buildCurrentPath() {
      let path = this.$router.history.current.path;
      let getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);
      
      return '/securite/' + path;
    },
    emptyFileInput() {
      this.formdata.file = null;
    },
    closeModal(object) {
      if(object === 'save') {
        this.$refs["saveModal"].hide();
        this.initValues();
      } else if(object === 'edit') {
        this.$refs["editModal"].hide();
        this.initValues();
      } else {
        this.$refs["importModal"].hide();
      }
    },
    openSaveDialog() {
      this.$refs["saveModal"].show();
    },
    openEditDialog(data) {
      this.formdata.slug = data.slug
      this.formdata.name = data.name
      this.formdata.zone = data.zone
      this.formdata.contacts = data.contacts
      this.$refs["editModal"].show();
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openDialog(data) {
      this.$refs.singleDataModal.show();
      this.singleData = data;
    },

    /* ---------------------
      | RECORD REMOVAL HANDLER
       */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { pid: id }),
      });
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Voulez-vous supprimer cet enregistrement ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteData();
          }
        });
    },
    deleteData() {
      let id = this.$route.query.pid;
      if (id !== "" || id !== null) {
        this.$store
          .dispatch("ModuleSecurity/remove", {
            item: id,
            path: this.buildCurrentPath(),
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Action Réussie`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Enregistrement supprimé avec succès!`,
              },
            });
            this.revertUrl();
          })
          .catch((error) => {
            if (typeof error === "object") {
              for (let err in error) {
                this.swalError("Erreur", err, "error");
              }
            } else {
              this.$vs.notify({
                title: "Erreur",
                text: error,
                color: "danger",
                icon: "error",
              });
            }
          });
      } else {
      }
    },
    revertUrl() {
      this.$router.replace({ name: "apps-securite-astreinte-user" });
    },
    // END ---
    // MODAL FORM TOOL --------------
    initValues() {
      this.formdata.slug = null;
      this.formdata.name = "";
      this.formdata.contacts = [];
      this.formdata.zone = "";
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.name === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Nom"), "error");
      } else if (this.formdata.zone === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Département"),
          "error"
        );
      } else {
        let global = {};
        let formData = new FormData();

        formData.append("slug", this.formdata.slug);
        formData.append("name", this.formdata.name);
        formData.append("zone", this.formdata.zone);
        formData.append("contacts", this.formdata.contacts);

        global = {
          formdata: formData,
          path: this.buildCurrentPath(),
        };

        this.isLoading = true;

        if(this.formdata.slug !== null && this.formdata.slug !== '') {
          this.$store.dispatch("ModuleSecurity/editUser", global).then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Modification effectué avec succès!`,
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
              
              this.swalError("Erreur", error.message, "error");
            });
        } else {
          this.$store.dispatch("ModuleSecurity/save", global).then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Enregistrement modifié avec succès!`,
                },
              });
              this.initValues();
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
              
              this.swalError("Erreur", error.message, "error");
            });
        }
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
    updateCurrFile(e) {
      if (e.target.files && e.target.files[0]) {
        this.file = e.target.files[0];
      }
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
      appDesc,
    };
  },
};
</script>
